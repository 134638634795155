<template>
  <div class="auth-wrapper auth-v1 px-2">
    <div :class="isTablet ? '' : 'w-50'" class="appointment-inner">
      <!-- Forgot Password v1 -->
      <b-card class="mb-0 appointment-card">
        <b-card-title class="mb-1 text-white">{{$t('Appointment Information')}}</b-card-title>
        <hr>
        <b-card class="card-app-design">
          <b-card-title class="mb-75 font-weight-light b-card-title">
              <span class="text-primary">{{formattedDayNumber}} {{formattedMonthName}} {{formattedYearNumber}}</span> tarihinde, <span class="text-primary">{{formattedDayName}}</span> günü <span class="text-primary">{{start_time}} - {{end_time}}</span> saatleri arasında <span class="text-primary">{{appointmentInfo.trainer_name}} {{appointmentInfo.trainer_last_name}}</span> eğitmeni tarafından alacağınız <span class="text-primary">{{appointmentInfo.activity_name}}</span> aktivite randevunuz başarıyla tanımlanmıştır.
              <span v-if="appointmentInfo.room_name != null">Aktivite {{appointmentInfo.room_name}} salonunda gerçekleşecektir.</span>
          </b-card-title>

          <b-card-title class="mt-1 mb-75 text-primary font-weight appointment-summary">{{$t('Appointment Summary')}}</b-card-title>
          <div>
            <div class="card-group justify-content-around text-center d-flex flex-wrap">
              <div class="card">
                <p class="card-text mb-25">
                  <b-badge variant="light">
                    <b class="text-primary">{{$t('Trainer')}}</b>
                  </b-badge>
                </p>
                <p class="mb-0 info-text">
                  {{ appointmentInfo.trainer_name }}
                  {{ appointmentInfo.trainer_last_name }}
                </p>
              </div>
              <div class="card">
                <p class="card-text mb-25">
                  <b-badge variant="light">
                    <b class="text-primary">{{$t('Activity')}}</b>
                  </b-badge>
                </p>
                <p class="mb-0 info-text">
                  {{ appointmentInfo.activity_name }}
                </p>
              </div>
              <div class="card">
                <p class="card-text mb-25">
                <b-badge variant="light">
                  <b class="text-primary">{{$t('Appointment Date')}}</b>
                </b-badge>
                </p>
                <p class="mb-0 info-text">
                  {{formattedDayNumber}} {{formattedMonthName}} {{formattedYearNumber}}
                </p>
              </div>
              <div class="card">
                <p class="card-text mb-25">
                <b-badge variant="light">
                  <b class="text-primary">{{$t('Appointment Time')}}</b>
                </b-badge>
                </p>
                <p class="mb-0 info-text">
                  {{ start_time }} - {{end_time}}
                </p>
              </div>
            </div>
          </div>

          <!-- button -->
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="outline-primary"
            block
            :to="{name:'myAppointments'}"
            class="my-appointments"
          >
            {{$t('My Appointments')}} <feather-icon icon="ChevronRightIcon" />
          </b-button>
        </b-card>
      </b-card>
      <!-- /Forgot Password v1 -->
    </div>
  </div>
</template>

<script>
import VuexyLogo from "@core/layouts/components/Logo.vue";
import Ripple from "vue-ripple-directive";
import {
  BCard,
  BLink,
  BCardText,
  BCardTitle,
  BFormGroup,
  BFormInput,
  BForm,
  BButton,
  BBadge,
} from "bootstrap-vue";

export default {
  components: {
    VuexyLogo,
    BCard,
    BLink,
    BCardText,
    BCardTitle,
    BFormGroup,
    BFormInput,
    BButton,
    BForm,
    BBadge,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      formattedDayNumber:'',
      start_time: '',
      end_time: '',
      formattedMonthName: '',
      formattedYearNumber: '',
      formattedDayName: '',
      appointmentInfo: {
        time_table_id: null,
        subject_title: null,
        description: null,
        trainer_product_id: null,
        trainer_id: null,
        activity_date: null,
        start_date: null,
        end_date: null,
        capacity: null,
        room_id: null,
        counter: null,
        person_id: null,
        trainer_name: null,
        trainer_last_name: null,
        activity_name: null,
        product_title: null,
        room_name: null,
      },
      ml: [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ],
    };
  },
  methods: {
    getAppointmentDetails() {
      this.$database.AppointmentService.get_appointment_details_by_time_table_id(
        this.$route.params.timeTableDetailId
      ).then((res) => {
        if (res.is_success === true || res.is_success === 1) {
          this.appointmentInfo = res.result;

          let hour = this.appointmentInfo.start_date.split('T')[1];
          this.start_time = hour.split(':')[0] + ':' + hour.split(':')[1];

          let date = new Date(this.appointmentInfo.end_date)
          let incrementSecond = date.getTime() + 1000
          let newDate = new Date(incrementSecond)
          let hourMinuteSecond = newDate.toString().split(' ')[4]
          let formattedHMS = hourMinuteSecond.split(':')[0] + ':' + hourMinuteSecond.split(':')[1]
          this.end_time = formattedHMS

          this.formattedDayNumber = this.appointmentInfo.start_date.split('T')[0].split('-')[2]
          this.formattedMonthName = this.$t(this.ml[this.appointmentInfo.start_date.split('T')[0].split('-')[1] - 1])
          this.formattedYearNumber = this.appointmentInfo.start_date.split('T')[0].split('-')[0]


          let date2 = new Date(this.appointmentInfo.start_date)
          this.formattedDayName = this.$t([
            "Sunday",
            "Monday",
            "Tuesday",
            "Wednesday",
            "Thursday",
            "Friday",
            "Saturday",
          ][date2.getDay()])
            }
          });
    },
  },
  mounted() {
    this.getAppointmentDetails();
  },
};
</script>

<!-- stillendirme -->
<style lang="scss" src="@/styles/scss/appointmentInfo.scss"></style>
<style lang="scss" src="@/styles/scss/appointmentInfoScoped.scss" scoped></style>
